<template>
    <div class="flex flex-wrap">
        <div class="w-full">
            <perfect-scrollbar class="pb-3">
                <div class="flex flex-row mb-0 p-1 gap-2 bg-gray-50 rounded-lg justify-between" :class="customHeaderClass">
                    <div class="text-center px-3 py-2 rounded-md justify-center items-center gap-2 inline-flex flex-1 cursor-pointer"
                         v-for="(tab, index) in tabs"
                         :key="index"
                         v-on:click="toggleTabs(index)"
                         v-bind:class="{'shadow bg-white': openTab === index}"
                    >
                        <div class="text-gray-500 text-sm font-semibold leading-tight rounded block cursor-pointer whitespace-nowrap"
                             v-bind:class="{'text-slate-700 bg-white': openTab === index}">
                            {{tab.title}}
                            <span v-if="tab.counter" class="px-2 py-1 mix-blend-multiply bg-gray-100 rounded-2xl text-slate-700 text-xs font-normal leading-none">
                                {{tab.counter}}
                            </span>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>

            <div class="border border-gray-100 my-5"></div>
            <div class="flex flex-col min-w-0">
                <div class="tab-content tab-space">
                    <slot :name="tabs[openTab].slotName"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        tabs: Array,
        customHeaderClass: String
    },
    data() {
        return {
            openTab: 0
        }
    },
    methods: {
        toggleTabs: function(tabNumber){
            this.openTab = tabNumber
        }
    }
}
</script>
<style>
.ps__rail-x, .ps__rail-y { opacity: 0.6; }
</style>
